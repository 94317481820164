body {
  background-color: #f5f5f5; /* This corresponds to gray-100 in Tailwind CSS */
}

.discom-list::-webkit-scrollbar {
    display: none;
}

.state-list::-webkit-scrollbar {
    display: none;
}

.powerPlant .react-datepicker-wrapper{
    width: 100%!important;
}

.react-datepicker__day--outside-month{
    color: gray !important;
}

/* Loader.css */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container {
  position: relative;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}
